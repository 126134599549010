div.saldos {
    grid-column: 1/3;
    grid-row: 3/5;
    padding: 16px 24px;
    background-color: #f2f2f2;
    border-radius: 12px;

    div.titleContainer {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 20px;

        img {
            width: 48px;
            margin-right: 20px;
        }

        h4 {
            font-size: 35px;
            font-weight: 300;
            color: #a7a7a7;
        }
    }

    div.row {
        display: grid;
        grid-template-columns: 32px 1fr 1fr 1fr;
        grid-template-rows: auto;
        -webkit-align-items: center;
        align-items: center;
        grid-column-gap: 16px;
        -webkit-column-gap: 16px;
        column-gap: 16px;
        margin-bottom: 24px;

        >p {
            color: #a7a7a7;
            font-size: 17px;
            font-weight: 300;
            width: 32px;
        }

        div.card {
            border-radius: 10px;
            color: #63c3d1;
            text-align: center;
            background-color: #fff;
            padding: 8px 8px 0;

            &.total {
                background-color: #63c3d1;
                color: #fff;

                .divider {
                    background-color: #fff;
                }
            }

            p {
                font-size: 20px;
                margin-bottom: 8px;
                font-weight: 300;

                img {
                    max-width: 32px;
                    margin-right: 20px;
                }
            }

            .divider {
                padding: 1px 0;
                background-color: #63c3d1;
            }

            h4 {
                font-size: 24px;
                font-weight: 500;
            }
        }

        @media all and (max-width: 1760px) {
            grid-template-columns: 32px 1fr 1fr;
            grid-template-rows: auto auto;
            grid-row-gap: 16px;
            row-gap: 16px;
            -webkit-align-items: center;
            align-items: center;

            >p {
                grid-column: 1/2;
                grid-row: 1/3;
            }

            div.card {
                &.total {
                    grid-column: 2/3;
                    grid-row: 1/3;
                }

                &:nth-of-type(2) {
                    grid-column: 3/4;
                    grid-row: 1/2;
                }

                &:last-of-type {
                    grid-column: 3/4;
                    grid-row: 2/3;
                }
            }
        }
    }

    button {
        font-size: 14px;
        line-height: 19px;
        cursor: pointer;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        background-color: transparent;
        border: 1px solid #63c3d1;
        text-align: center;
        border-radius: 10px;
        margin-right: auto;
        padding: 8px 12px;
        width: 200px;

        a {
            text-decoration: none;
            color: #63c3d1;
        }
    }

    @media all and (max-width: 1240px) {
        grid-template-columns: 32px 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-column: 1/2;
        grid-row: 3/4;

        div.row {
            grid-template-columns: 32px 1fr 1fr 1fr;
            grid-template-rows: auto;

            >p {
                grid-column: 1/2;
                grid-row: 1/2;
            }

            div.card {
                &.total {
                    grid-column: 2/3;
                    grid-row: 1/2;
                }

                &:nth-of-type(2) {
                    grid-column: 3/4;
                    grid-row: 1/2;
                }

                &:last-of-type {
                    grid-column: 4/5;
                    grid-row: 1/2;
                }
            }
        }
    }

    @media all and (max-width: 900px) {
        div.row {
            grid-template-columns: 32px 1fr 1fr;
            grid-template-rows: auto auto;
            grid-row-gap: 16px;
            row-gap: 16px;
            -webkit-align-items: center;
            align-items: center;

            >p {
                grid-column: 1/2;
                grid-row: 1/3;
            }

            div.card {
                &.total {
                    grid-column: 2/3;
                    grid-row: 1/3;
                }

                &:nth-of-type(2) {
                    grid-column: 3/4;
                    grid-row: 1/2;
                }

                &:last-of-type {
                    grid-column: 3/4;
                    grid-row: 2/3;
                }
            }
        }
    }
}