.resumen {
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
    width: 40%;

    .row {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;

        >div {
            width: 45%;
        }
    }

    button {
        color: #fff!important;
        background: linear-gradient(90deg,#63d1a2,#63c3d1)!important;
        border-radius: 21px!important;
        text-transform: none!important;
        font-weight: 700;
        font-family: "Roboto",sans-serif!important;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: none;
        padding: 8px 32px;
        text-align: center;
        cursor: pointer;
    }
}