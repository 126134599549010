.containerTitle{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;   
    margin-top: 20px;
   
    h1{ 
        text-align: center;
        color:#9373B1;
        font-size: 25px;
        font-weight: 600;
    }
    p{
        margin-top: 20px;
        text-align: center;
        font-size: 15px;
        color: #999999;
        font-weight: 400;
        width: 150%;
    }
}
.containerBtn{
    span{ 
        cursor: pointer;
        color:#8A8A8A;
        font-weight:550;
    }
}