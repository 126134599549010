main.main {
    height: calc(100vh - 182px);

}
button.button {
    border: 1px solid #63C3D1;
    color: #63C3D1;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 25px;
    background-color: transparent;
    padding: 0.5em 1.5em;
    margin: auto;
    margin-bottom: 1em;
}
h4.title {
    text-align: center;
    color: #A7A7A7;
    font-size: 1.25em;
    margin: auto;
    margin-bottom: 1em;
    font-weight: 300;
}