main {
  height: calc(100vh - 115px);
  padding: 0 0.5em;
  max-width: 100vw;
  overflow: hidden;
}

main .saldo-actual {
  position: absolute;
  top: 30px;
  right: 100px;
  z-index: 1200;
  font-size: 1.25rem;
  color: #fff;
}
main .saldo-actual p {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
main .saldo-actual span {
  flex-grow: 1;
  text-align: right;
}

.not-showing-header {
  color: #fff;
}
.row-grid {
  height: 80%;
  margin-bottom: 1%;
  position: relative;
  overflow: scroll;
  width: 100%;
}
