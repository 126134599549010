main.main {
    min-height: calc(100vh - 115px);
    padding: 0 0.5em;
    overflow-y: scroll;

    div.container {
        padding: 44px 18px;

        div.cards {
            display: grid;
            grid-template-columns: repeat(4, calc(25% - 16px));
            grid-template-rows: auto auto 130px auto;
            grid-column-gap: 16px;
            -webkit-column-gap: 16px;
            column-gap: 16px;
            grid-row-gap: 20px;
            row-gap: 20px;

            @media all and (max-width: 1240px) {
                grid-template-columns: 1fr;
                grid-template-rows: auto auto auto auto;
            }
        }
    }
}