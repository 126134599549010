.container {
    max-width: 650px;
    margin: auto;
    
    label {
        font-size: 1em;
     
        color: #9373B1;
        font-weight: bold;
    }
    input {
        outline: none;
        border: none;
        border-bottom: 1px solid #707070;
        font-size: 1em;
        color: #686868;
        margin-bottom: 1em;
        width: 100%;
        font-weight: 300;

        &::placeholder {
            color: #A7A7A7
        }
    }
}