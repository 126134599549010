.container{
    padding: 1em 4em;
    position: sticky;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    height: 10vh;
}
.logo{
    max-width: 110px;
    max-height: 100%;
}