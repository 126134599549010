.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    button {
        border-radius: 100px;
        font-size: 1em;
        text-align: center;
        padding: 0.75em;
        width: 300px;
        border: none;
        cursor: pointer;
    }
    
    .continuar {
        background: transparent linear-gradient(90deg, #9373B1 0%, #63C3D1 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        color: #FFF;
    }
    .cancelar {
        background: transparent;
        color: #A7A7A7;
        margin-bottom: 1em;
    }
}