div.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    a {
        border-radius: 10px;
        box-shadow: 0px 3px 10px #0000001A;
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 350px;
        cursor: pointer;
        text-decoration: none;
        margin-bottom: 1.5em;
        min-height: 480px;
        img {
            object-fit: cover;
            width: 115px;
            aspect-ratio: 1 / 1;
            margin-bottom: 45px;
        }
        
        h4 {
            font-size: 1.5em;
            color: #5B67A9;
            text-align: center;
            font-weight: 300;
            margin-bottom: 45px;
        }
        p {
            font-size: 1.125em;
            color: #2C519F;
            text-align: center;
            font-weight: 300;
        }
    }
}