div.solicitudes {
    background-color: #f2f2f2;
    border-radius: 12px;
    padding: 16px;
    grid-column: 3/5;
    grid-row: 3/5;

    div.titleContainer {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 16px;

        img {
            width: 56px;
            margin-right: 20px;
        }

        h4 {
            font-size: 35px;
            line-height: 46px;
            font-weight: 300;
            -webkit-flex-grow: 1;
            flex-grow: 1;
            color: #a7a7a7;
        }
    }

    div.row {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        padding-left: 36px;
        margin-bottom: 36px;

        div.dato {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;

            &:first-of-type {
                margin-right: 40px;
            }

            img {
                height: 86px;
                margin-right: 20px;
                display: inline
            }

            h4 {
                display: inline;
                font-size: 48px;
                line-height: 64px;
                color: #e56da1;
            }
        }
    }

    button {
        font-size: 14px;
        line-height: 19px;
        cursor: pointer;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        background-color: transparent;
        border: 1px solid #e56da1;
        text-align: center;
        border-radius: 10px;
        margin-right: auto;
        padding: 8px 12px;
        width: 200px;

        a {
            text-decoration: none;
            color: #e56da1;
        }
    }

    @media all and (max-width: 1240px) {
        grid-column: 1/2;
        grid-row: 4/5;
    }
}