.totalLabelsContainer {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
}

.amountLabels {
  display: flex;
  gap: 12px;
  width: 100%;
  margin-left: 12px;
  h4{
    display: flex;
    flex: 1;
  }
}

.fileUploadWrapper {
  display: flex;
  flex-direction: row-reverse;
}

.fileUploadBtn {
  background: transparent linear-gradient(90deg, #9373b1 0%, #63c3d1 100%)
    no-repeat padding-box;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  transition: background 0.3s ease;

  &:hover {
    background: linear-gradient(90deg, #13d5a5 0%, #13c5d5 100%);
  }
}

.fileUploadInput {
  display: none;
}

.grid {
  height: 100% !important;
}

.textArea {
  width: 100%;
  border: 1px solid darkgrey;
}

.formFooter {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.submitButton {
  margin-bottom: 6px !important;
}

.inputsContainer {
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  justify-content: space-between;
}
