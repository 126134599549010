div.login {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(180deg, #63c3d1, #9373b1);

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    div.container {
        width: 600px;
        color: #5567ae;
        padding: 40px 0;
        height: 100%;

        div.logoContainer {
            padding: 32px 32px 0;
            background-color: #f7f7f7;
            margin-bottom: 24px;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            flex-direction: column;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            border-radius: 32px;
            box-shadow: 0 10px 16px rgb(0 0 0 / 16%);
            width: 100%;

            p {
                text-align: center;
                font-weight: 700;
                font-size: 36px;

                span {
                    font-weight: 300;
                }
            }

            img {
                width: 300px;
                -webkit-transform: translateY(-32px);
                transform: translateY(-32px);
            }
        }

        div.qrContainer {
            padding: 32px;
            background-color: #f7f7f7;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            flex-direction: column;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -webkit-align-items: center;
            align-items: center;
            border-radius: 32px;
            box-shadow: 0 10px 16px rgb(0 0 0 / 16%);
            width: 100%;
            height: 480px;

            h4 {
                text-align: center;
                margin-bottom: 8px;
                font-weight: 700;
                font-size: 36px;
            }

            p {
                text-align: center;
                margin-bottom: 16px;
                font-size: 24px;
            }

            button.button {
                color: #fff !important;
                background: transparent linear-gradient(90deg, #9373b1, #63c3d1) 0 0 no-repeat padding-box;
                border-radius: 21px !important;
                text-transform: none !important;
                font-weight: 400;
                font-family: "Roboto", sans-serif !important;
                margin-top: 2rem;
                border: none;
                padding: 12px;
                text-align: center;
                cursor: pointer;
                font-size: 20px;
                width: 100%;
            }
        }
    }
}