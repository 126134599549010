.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 650px;
    margin: auto;
    margin-bottom: 2em;
    position: relative;
    
    label {
        font-size: 1em;
        color: #9373B1;
        font-weight: bold;
        width: 100%;
    }
    span {
        position: absolute;
        font-size: 1.5em;
        left: 0;
        bottom: 0.25em;
        color: #686868;
    }
    input {
        outline: none;
        border: none;
        border-bottom: 1px solid #707070;
        font-size: 2em;
        color: #686868;
        margin-bottom: 0.125em;
        width: 100%;
        text-align: right;
        padding-left: 0.333333333em;
        box-sizing: border-box;
        &::placeholder {
            color: #A7A7A7;
            font-size: 0.8em;
        }
    }
}