header.header {
    background: transparent linear-gradient(90deg, #9373B1 0%, #63C3D1 100%) 0% 0% no-repeat padding-box;
    padding: 0 3em;
    height: 100px;
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    overflow: hidden;

    div {
        display: flex;
        align-items: center;

        img {
            margin-right: 1rem;
        }

        p {
            text-align: left;
            font-size: 1.5em;
            color: #FFFFFF;
        }
    }
    img {
        margin-left: auto;
        opacity: 0.1;
    }
}

.menuIcon {
    color: #FFF
}
.drawer {
    width: 270px;
    box-shadow: 0px 3px 6px #00000029;
    .MuiPaper-root {
        width: 270px
    }
}
.userDescription {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 150px;
    background: #f2f2f2 0% 0% no-repeat padding-box;
}
.avatar {
    height: 6rem;
    width: 6rem;
    border-radius: 100%
}
.usernameContainer {
    line-height: 1rem;
    padding-top: 0.6rem;
    margin-top: 31px;
    p {
        color: #a7a7a7;
        font-size: 1rem;
        font-weight: 400;
        margin: 1rem 0;
        padding: 0
    }
}
.logout {
    display: flex;
    cursor: pointer;
    p {
        font-weight: 500;
    }
    img {
        margin-right: 0.6rem;
        width: 1.3rem
    }
}
.listContainer {
    background: transparent linear-gradient(180deg, #9373B1 0%, #63C3D1 100%) 0% 0% no-repeat padding-box;
    flex-grow: 1;
    padding-left: 3%;
}
.drawerFooter {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4vh;
    color: #707070;
    font-size: 0.8rem;
    padding: 0.6rem;
    font-weight: 400
}
.drawerHandler {
    height: 100%;
    width: 100vw;
    background-color: transparent;
    position: absolute;
    z-index: 1150;
    right: 0;
    top: 0
}
.link {
    margin-top: 0.5rem;
    text-decoration: none;
    display: block;
}
.textColor {
    color: #ffffff;
    font-weight: 300;
    font-size: 0.85rem;
    flex-grow: 1
}