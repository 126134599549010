.page{
    display: flex;
    background-color: #f0f0f0;
    height: 90vh;
    justify-content: center;
    padding-block: 2em;
    align-items: center;
    box-sizing: border-box;
}
.container{
    background-color: #fff;
    min-width: 700px;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 100%;
    padding: 2em 3em;
    box-sizing: border-box;

}
.container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.container::-webkit-scrollbar:vertical {
    width:8px;
    height: 20px;
    margin-top: 10px;
}

.container::-webkit-scrollbar-button:increment,.container::-webkit-scrollbar-button {
    display: none;
} 

.container::-webkit-scrollbar:start{
    margin-top: 15px;
}

.container::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.container::-webkit-scrollbar-track {
    border-radius: 50px;  
}
.containerTitle{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;   
    max-width: 350px;
   
    h1{ 
        text-align: center;
        color:#9373B1;
        font-size: 25px;
        font-weight: 600;
    }
    p{
        margin-top: 20px;
        text-align: center;
        font-size: 15px;
        color: #999999;
        font-weight: 400;
        width: 150%;
    }
}
.linear{ 
    border-bottom: 1px solid #EEEEEE;
    width: 150%;
    margin-top: 40px;
}

.close{ 
    width: 100%;
    display: flex;
    justify-content: end;
    position: relative;
    img{ 
        cursor: pointer;
        z-index: 2;
        right: -30px;
        top: -15px;
        position: absolute;
    }
   

}