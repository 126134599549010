main.main {
    height: calc(100vh - 182px);
}
button.button {
    border: 1px solid #63C3D1;
    color: #63C3D1;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 25px;
    background-color: transparent;
    padding: 0.5em 1.5em;
    margin: auto;
    margin-bottom: 1em;
}

h4.title {
    text-align: center;
    color: #A7A7A7;
    font-size: 1.25em;
    margin: auto;
    margin-bottom: 1em;
    font-weight: 300;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
        width: 100%;
    }

    > img {
        max-width: 150px;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        object-fit: cover;
        margin-bottom: 1.25em;
    }
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;

    button {
        border-radius: 100px;
        font-size: 1em;
        text-align: center;
        padding: 0.75em;
        width: 300px;
        border: none;
        cursor: pointer;
        margin-bottom: 1em;
    }

    .submit {
        background: transparent linear-gradient(90deg, #9373B1 0%, #63C3D1 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        color: #FFF;
    }

    .volver {
        background: transparent;
        color: #A7A7A7;
    }
}