* {
    font-family: "Roboto", sans-serif;
    margin: 0;
  }
  
  body {
      margin: 0;
      text-decoration: none!important;
  }  
  
  label {
      font-weight: bold!important;
  }

*::-webkit-scrollbar {
    -webkit-appearance: none;
}

*::-webkit-scrollbar:vertical {
    width:8px;
    height: 20px;
    margin-top: 10px;
}

*::-webkit-scrollbar-button:increment,*::-webkit-scrollbar-button {
    display: none;
} 

*::-webkit-scrollbar:start{
    margin-top: 15px;
}

*::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

*::-webkit-scrollbar-track {
    border-radius: 50px;  
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
}