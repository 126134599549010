.container {
    width: 100%;
    height: 70vh;
}
.title {
    display: flex;
    justify-content: center;
    color: #63d1a2;
    font-weight: 300;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.gridContainer {
width: 100%;
height: 70%;
margin-bottom: 0.5em;
position: relative;
}
.closeButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.button {
    width: 80px;
    height: 2rem;
    color: #ffffff;
    opacity: 1;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%);
    border-radius: 21px!important;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
}
.button span {
    color: #FFF
}
.button:hover {
    background: linear-gradient(90deg, #13d5a5 0%, #13c5d5 100%)
}
.button:disabled {
    background: "#A7A7A7",
}